import * as React from 'react';
import {PageBackground} from '../PageBackground';
import {PageHeader} from '../PageHeader';
import {VSpacer} from '../VSpacer';
import {Images} from '../../images/Images';
import {HAlign, HAligner} from '../HAligner';
import {Text, TextSize, TextWeight} from '../Text';
import {PageFooter} from '../PageFooter';
import {PictureFrame} from '../PictureFrame';
import {SiteButton, SiteButtonKind} from '../SiteButton';
import {HSpacer} from '../HSpacer';
import {ScheduleDemoButton} from '../ScheduleDemoButton';

// declare var Calendly:any;

export class MainPage extends React.Component<any, any>
{
    render()
    {
        return  <PageBackground>
                    <PageHeader/>

                    <VSpacer size={15}/>
                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'}>
                            <div className={'col-sm-12'}>
                                <VSpacer size={15}/>
                                <HAligner align={HAlign.Center}>
                                    <Text isHighlight={false} size={TextSize.h1} weight={TextWeight.w400}>
                                        Ergalio:&nbsp;
                                    </Text>
                                    <Text isHighlight={false} size={TextSize.h1} weight={TextWeight.w400}>
                                        IT Services you can depend on
                                    </Text>
                                    {/*<Text isHighlight={false} size={TextSize.h1} weight={TextWeight.w400} >*/}
                                    {/*    ERGALIO*/}
                                    {/*</Text>*/}
                                    {/*<Text> </Text>*/}
                                    {/*<Text isHighlight={false} size={TextSize.h1} weight={TextWeight.w300}>*/}
                                    {/*    means*/}
                                    {/*</Text>*/}
                                    {/*<Text> </Text>*/}
                                    {/*<Text/>*/}
                                    {/*<Text isHighlight={true} size={TextSize.h1} weight={TextWeight.w300}>*/}
                                    {/*    tool*/}
                                    {/*</Text>*/}
                                    {/*<Text> </Text>*/}
                                    {/*<Text isHighlight={false} size={TextSize.h1} weight={TextWeight.w300}>*/}
                                    {/*    in Greek*/}
                                    {/*</Text>*/}
                                </HAligner>
                            </div>

                        </div>
                    </div>

                    <VSpacer size={35}/>
                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{backgroundColor:'#FAFAFA', border:'1px solid #EFEFEF', boxShadow:'2px 2px 4px 0 rgba(202,202,202,0.50)'}}>
                            <div className={'col-lg-4'} style={{padding:'30px 20px 30px 30px'}}>
                                <VSpacer size={15}/>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w400}>
                                    Outsourced Software Development & Testing
                                </Text>

                                <VSpacer size={15}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>

                                    Partner with us to create your business applications faster and with higher quality.
                                    <br/><br/>
                                    Use our staff to help accelerate your software development and software testing initiatives.
                                    <br/><br/>

                                    Achieve substantial cost savings when using our staff. We are able to complete projects at a fraction of the traditional cost.

                                    <br/><br/>

                                </Text>


                                <div style={{display:'flex', justifyContent:'flex-start', border: '1px solid transparent'}}>
                                    <SiteButton text={'Learn More'} link={'/services'}/>
                                    <HSpacer size={10}/>

                                    <ScheduleDemoButton/>
                                </div>


                            </div>
                            <div className={'col-lg-8'} style={{padding:'40px 40px'}}>
                                <PictureFrame style={{borderColor:'rgba(0,0,0,0.05)'}}>
                                    {/*<img width={'100%'} src={Images.WorkspaceTopologyView}/>*/}
                                    <img width={'100%'} src={Images.ITServices__Home2}/>
                                </PictureFrame>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent',
                                                        marginTop:'50px', marginBottom:'50px'}}>
                        <div className={'row'}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w400}>
                                    Why work with us?
                                </Text>
                                <VSpacer size={13}/>

                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300} lineHeight={1.2}>
                                    We know that you have a great deal of choice when picking the right outsourcing and software development partner.
                                    When working with us, know that we value transparency, reliability, and a strong work ethic across all of our staff and our management team.
                                    We genuinely enjoy working with our customers and the speed and quality we bring to all of our projects.
                                </Text>

                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent',
                        marginTop:'50px', marginBottom:'50px'}}>
                        <div className={'row'}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w400}>
                                    What IT services do we offer?
                                </Text>
                                <VSpacer size={13}/>

                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300} lineHeight={1.2}>
                                    We specialize in software development and testing, covering all parts of software development from start to finish.

                                    In particular, we work with each customer to understand their processes and ways of working, so that our staff can seamlessly fit into your existing workflows.

                                    Here are the services we offer:
                                    <br/><br/>

                                    <Text isHighlight={false} size={TextSize.h4} weight={TextWeight.w500} lineHeight={1.3}>
                                    <ul>
                                        <li>
                                            <a href={'/services'}>→ Software Development</a>
                                        </li>
                                        <li>
                                            <a href={'/services'}>→ Software Testing and Quality Assurance (QA)</a>
                                        </li>
                                        <li>
                                            <a href={'/services'}>→ Outsourced Developers</a>
                                        </li>
                                        <li>
                                            <a href={'/services'}>→ Outsourced QA</a>
                                        </li>
                                    </ul>
                                    </Text>

                                </Text>

                            </div>
                        </div>
                    </div>

                    <PageFooter />

                </PageBackground>;
    }
}

export default () => <MainPage/>;






