import * as React from 'react';
import {SiteButton, SiteButtonKind} from './SiteButton';

export interface ScheduleDemoButtonProps
{
    kind?:SiteButtonKind;
}

export class ScheduleDemoButton extends React.Component<ScheduleDemoButtonProps, any>
{
    render()
    {
        const {kind=SiteButtonKind.Dark} = this.props;

        return <SiteButton kind={kind} text={'Contact Us'} link={'mailto:sales@ergalio.net'}/>

        // return <SiteButton kind={kind} text={'Contact Us'} link={'#'}
        //             onClick={()=>{(window.Calendly as any).showPopupWidget('https://calendly.com/ergalio/30min');return false;}}/>
    }
}